'use client';

import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <html lang="pl">
      <body className="bg-white text-center">
        <main className="container mx-auto p-5">
          <div>
            <div className="rounded border-2 border-red-400 bg-red-100 p-4 text-red-600">
              <h3>Coś poszło nie tak!</h3>
              <p className="my-2">Błąd: {error.message}</p>
            </div>
          </div>
        </main>
      </body>
    </html>
  );
}
